.overlay {
    z-index: 1;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0000008f;
}

.modalController {
    width: 500px;
    height: auto;
    background: #ffffff;
    border-radius: 30px;
}

.contentHeader {
    display: flex;
    justify-content: end;
    padding: 20px;
    /* border-bottom: 1px solid #F1F1F3; */
}

.contentHeader img {
    width: 30px;
    height: 30px;
}

.contentHeader img:hover {
    cursor: pointer;
}

.contentModal {
    margin-top: -30px;
    padding: 20px;
    display: grid;
    justify-items: center;
    grid-gap: 20px;
}

@media screen and (max-width: 800px) {
    .contentModal {
        padding: 20px;
        grid-gap: 10px;
    }
    
    .contentModal img {
        width: 70px;
        height: 70px;
    }


}