@font-face {
  font-family: 'IBM Plex Sans Thai Looped';
  src: url('./assets/fonts/IBMPlexSansThai-Regular.ttf');
}

* {
  font-family: 'IBM Plex Sans Thai Looped';
}

body {
  box-sizing: border-box;
  margin: 0;
  font-family: 'IBM Plex Sans Thai Looped';
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

/* Scroll bar stylings */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #E1E3E9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2CC2C0;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2CC2C0;
}

.bg__health__atm {
  /* position: fixed; */
  /* position: relative; */
  min-height: 100vh;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-image: url(./assets/images/background.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

iframe {
  border: 1px solid #E1E3E9;
}

.imgIcon {
  width: 40px;
}

.MuiButton-contained {
  font-weight: 700 !important;
  font-size: 20px !important;
}

.MuiButton-outlined {
  font-weight: 700 !important;
  font-size: 20px !important;
  background-color: #ffffff !important;
}

@media screen and (max-width: 1024px) {

  .MuiButton-contained {
    font-size: 16px !important;
  }

  .MuiButton-outlined {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 800px) {
  .imgIcon {
    width: 25px;
  }

  .MuiButton-contained {
    font-size: 14px !important;
  }

  .MuiButton-outlined {
    font-size: 14px !important;
  }
}