.container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    width: 530px;
    height: auto;
    background-color: #FFFFFF;
    border: 1px solid #E1E3E9;
    border-radius: 20px;

    padding: 50px 0;

    display: grid;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
}

.card img {
    width: 280px;
}

.logo {
    display: flex;
    justify-content: center;
}

.icon {
    display: flex;
    justify-content: center;
}

.icon img {
    width: 70px;
}

.change {
    fill: #E1E3E9;
}

.relative {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 20px;
    font-size: 16px;
}

.iconLogo {
    width: auto;
}

@media screen and (max-width: 1024px) {
    .iconLogo {
        width: 400px;
    }

    .card {
        width: 400px;
        padding: 20px;
        grid-gap: 20px;
    }

    .card img {
        width: 200px;
    }
    
}


@media screen and (max-width: 600px) {
    .iconLogo {
        width: 300px;
    }

    .card {
        width: 350px;
        padding: 20px;
        grid-gap: 15px;
    }

    .card img {
        width: 180px;
    }

    .icon img {
        width: 50px;
    }

    .boxMenu {
        width: 150px;
        height: 150px;
    }

    .boxMenuDis {
        width: 150px;
        height: 150px;
    }
}

@media screen and (max-width: 400px) {
    .card {
        width: 320px;
        grid-gap: 10px;
    }

    .card img {
        width: 150px;
    }

    .icon img {
        width: 40px;
    }
}