.overlay {
    z-index: 6;
    position: sticky;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(30px);
}

.modalContainer {
    background: #FFFFFF;
    border: 1px solid #eceef4;
    border-radius: 30px;
    width: 1200px;
    height: 650px;
    padding: 20px;
    margin: 20px;
}

.closeButtonContainer {
    display: flex;
    justify-content: flex-end;
}

.closeButtonContainer img {
    width: 30px;
    height: 30px;
}

.closeButtonContainer img:hover {
    cursor: pointer;
}

.headerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 30px;
}

.headerContainer .text {
    font-weight: 700;
    font-size: 32px;
    text-align: center;
    color: #2CC2C0;
    text-decoration: underline;
}

.imageContainer {
    margin-top: 20px;
    width: 100%;
    height: 500px;
    overflow: hidden;
}

.imageSlide {
    display: flex;
    width: 300%;
    height: 500px;
    transition: 0.4s;
}

.imageSlide .image {
    flex: 1;
    height: 500px;
}

.image img {
    width: 100%;
    height: 500px;
    object-fit: contain;
}

.dotGray {
    height: 10px;
    width: 10px;
    background-color: #747676;
    border-radius: 50%;
    display: inline-block;
}

.dotGreen {
    height: 10px;
    width: 10px;
    background-color: #2CC2C0;
    border-radius: 50%;
    display: inline-block;
}

.gap {
    display: flex;
    align-items: center;
    grid-gap: 50px;
}

@media screen and (max-width:800px) {

    .closeButtonContainer img {
        width: 20px;
        height: 20px;
    }

    .modalContainer {
        width: auto;
        height: auto;
        padding: 20px;
        margin: 0 20px;
    }

    .headerContainer {
        grid-gap: 10px;
    }

    .headerContainer .text {
        font-size: 14px;
    }

    .imageContainer {
        margin-top: 10px;
        height: 300px;
    }

    .imageSlide {
        width: 300%;
        height: 300px;
    }

    .imageSlide .image {
        height: 300px;
    }

    .image img {
        height: 300px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .icon img {
        width: 15px;
    }

    .icon2 img {
        width: 15px;
    }

    .gap {
        grid-gap: 30px;
    }
}

@media screen and (max-width:450px) {

    .closeButtonContainer img {
        width: 10px;
        height: 10px;
    }

    .modalContainer {
        width: auto;
        height: auto;
        padding: 15px;
        margin: 0 10px;
    }

    .headerContainer .text {
        font-size: 14px;
    }

    .imageContainer {
        height: 200px;
    }

    .imageSlide {
        width: 300%;
        height: 200px;
    }

    .imageSlide .image {
        height: 200px;
    }

    .image img {
        height: 200px;
        padding: 0;
    }

    .icon img {
        width: 10px;
    }

    .icon2 img {
        width: 10px;
    }

    .dotGray {
        height: 7px;
        width: 7px;
    }

    .dotGreen {
        height: 7px;
        width: 7px;
    }

    .gap {
        grid-gap: 10px;
    }
}