.btGreen {
    background-color: #E1F4EB;
    border: 1px solid #2CC2C0;
    border-radius: 10px;

    font-weight: 700;
    color: #2CC2C0;
    text-align: center;
    padding: 10px;
}

.btGay {
    background-color: none;
    border: none;
    border-radius: 10px;

    font-weight: 700;
    color: #263238;
    text-align: center;
    padding: 10px;
}

.btGay , .btGreen:hover {
    cursor: pointer;
}