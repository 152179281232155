.overlay {
    z-index: 1;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #0000008f; */
    backdrop-filter: blur(30px);
}

.modalController {
    width: auto;
    height: auto;
    background: #ffffff;
    border-radius: 30px;
}

.contentModal {
    padding: 50px;
    display: grid;
    justify-items: center;
    grid-gap: 20px;
}

.contentModal img {
    width: 100px;
    height: 100px;
}

.detail {
    font-size: 40px;
    color: #2CC2C0;
}

.button {
    width: 450px;
    height: 60px;
    background-color: #2CC2C0;
    border: 1px solid #2CC2C0;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 20px;
}

@media screen and (max-width: 800px) {
    .contentModal {
        padding: 20px;
        grid-gap: 10px;
    }
    
    .contentModal img {
        width: 70px;
        height: 70px;
    }

    .detail {
        font-size: 25px;
    }
    
    .button {
        width: 280px;
        height: 40px;
        font-size: 16px;
    }
}